<template>
  <div>User</div>
</template>

<script>
export default {
  name: 'User',
};
</script>

<style>

</style>
